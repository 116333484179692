.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  bottom: 0;
  background-color: #4267b1;
  width: 100%;
  z-index: 100;

  p {
    color: white;
    font-size: 0.8rem;
    text-align: center;
    margin: 0;
    padding: 0.8rem 0;
  }
}
