.card-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 60vh;
  min-width: 300px;
  overflow: auto;
  position: fixed;
  top: 10rem;
  left: 0;
  z-index: 100;

  @media only screen and (max-width: 990px) {
    min-width: 150px;
  }

  @media only screen and (max-width: 440px) {
    min-width: 150px;
    position: absolute;
    -webkit-box-align: center;
    align-items: center;
    top: 5rem;
    padding: 0 1rem;
    height: auto;
    overflow: hidden;
  }

  .card {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 15rem;
    min-width: 15rem;

    @media only screen and (max-width: 990px) {
      min-width: 10rem;
      width: 10rem;
    }

    @media only screen and (max-width: 440px) {
      width: auto;
      min-width: 5rem;
    }

    &-text {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 500;
      padding: 0.5rem;

      &:hover,
      &:active {
        color: #4267b1;
        cursor: pointer;
      }
    }
  }

  button {
    background-color: white;
    border: none;
  }
}
