.album {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 1rem;
  &-category {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  &-name {
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  &-photo {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
  &-button {
    background-color: #4267b1;
    text-transform: uppercase;
    color: white;

    &:hover,
    &:active {
      background-color: #2470aa;
    }
  }
}

.modal {
  &-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    align-self: center;

    @media only screen and (max-width: 440px) {
      font-size: 1rem;
    }
  }
  &-body {
    &-description {
      font-size: 1.2rem;

      @media only screen and (max-width: 440px) {
        font-size: 0.8rem;
      }
    }
  }
}

.ReactGridGallery {
  margin-top: 2rem;
}
