.spinner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 100;
  background-color: white;
  opacity: 0.8;
  padding: 10rem;

  @media only screen and (max-width: 440px) {
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .spinner-icon {
    width: 240px;
    height: auto;
  }
}
