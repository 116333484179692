.map {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.marker-camera {
  border: none;
  background: none;

  img {
    width: auto;
    height: 30px;
  }
}

.mapboxgl-popup {
  &-content {
    width: 150px;
  }
  &-close-button {
    font-size: 0.8rem;
    visibility: hidden;
  }
}
