.inquire-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 3rem;
  font-weight: 800;
  color: #4267b1;
}

.inquire {
  &-form {
    display: block;
    margin: 0;
    padding: 0;
    width: 1px;
    min-width: 100%;
    height: 2246px;
  }
}
