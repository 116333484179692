.intro {
  margin-bottom: 10rem;
  p {
    margin-bottom: 2rem;
  }

  &-button {
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid #4267b1;
    padding: 1rem 1.5rem;
    color: #4267b1;

    font-weight: 600;

    &:active,
    &:hover {
      text-decoration: none;
      color: white !important;
      background-color: #4267b1 !important;
    }
  }
}
