.image-slider {
  background-size: cover;
  margin: auto;
}

.carousel-item {
  -webkit-box-align: center;
  align-items: center;
  background-color: black;
}
